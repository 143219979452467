<template>
    <div class="container">
        <h1>Create Private Room</h1>
        <form @submit.prevent="createRoom" class="form-container">
            <input v-model="roomDetails.name" type="text" placeholder="Room Name" required />
            <input v-model="roomDetails.password" type="password" placeholder="Password" required />
            <input v-model.number="roomDetails.numberOfSongs" type="number" placeholder="Number of Songs" />
            <label class="checkbox-container">
                <input v-model="roomDetails.includeGames" type="checkbox" />
                Include Games
            </label>
            <label class="checkbox-container">
                <input v-model="roomDetails.includeAnimes" type="checkbox" />
                Include Animes
            </label>
            <button type="submit">Create Room</button>
            <p v-if="errorMessage">{{ errorMessage }}</p>
        </form>
    </div>
</template>

<script>
    import axios from 'axios';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    export default {
        data() {
            return {
                roomDetails: {
                    name: '',
                    password: '',
                    username:'',
                    numberOfSongs: 10,
                    includeGames: true,
                    includeAnimes: true
                },
                errorMessage: ''  // pour stocker les messages d'erreur
            };
        },
        methods: {
            getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
                return '';
            },

            createRoom() {
                const username = this.getCookie('username');  // Récupération du nom d'utilisateur depuis les cookies
                if (!username) {
                    this.errorMessage = 'You must be logged in to create a room.';
                    return;
                }

                this.roomDetails.username = username;  // Ajout de l'username aux roomDetails
                this.errorMessage = '';  // Réinitialiser le message d'erreur

                apiClient.post('/api/private_rooms', this.roomDetails)
                    .then(response => {
                        console.log('Room Created:', response.data);
                        this.$router.push({ name: 'PrivateRoom', params: { roomId: response.data.room_id } });
                    })
                    .catch(error => {
                        console.error('Error creating room:', error);
                        this.errorMessage = 'Failed to create room. Please check your input and try again.';
                    });
            }
        }
    };

</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh; /* Utilisez la hauteur totale de la fenêtre pour le centrage vertical */
    }

    .form-container {
        display: flex;
        flex-direction: column;
        width: 300px; /* ou la largeur que vous souhaitez pour votre formulaire */
    }

    .checkbox-container {
        display: flex;
        align-items: center;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="checkbox"],
    button {
        margin: 10px 0;
        padding: 10px;
    }

    button {
        background-color: #42b983;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

        button:hover {
            background-color: #333;
        }
</style>